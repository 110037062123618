import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IConfirmedReservationsPerShow,
  ICoupon,
  IProduct,
  IProduction,
  IProductionBootstrapDto,
  IProductionViewDto,
} from '@reservaties/api-interfaces';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import {
  map,
  filter,
  shareReplay,
  switchMap,
  distinctUntilChanged,
  take,
} from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductionService {
  private readonly ROOT_URL = '/api/production';

  private readonly _productionId$ = new ReplaySubject<string>();

  private readonly _production$ = new ReplaySubject<IProductionViewDto>();
  public readonly production$ = this._production$.asObservable();

  private readonly _bootstrap$ = new ReplaySubject<IProductionBootstrapDto>();
  public readonly bootstrap$ = this._bootstrap$.asObservable();

  private readonly cache = new BehaviorSubject(
    new Map<string, IProductionViewDto | null>()
  );

  constructor(private http: HttpClient) {
    this.production$ = this._productionId$.pipe(
      distinctUntilChanged(),
      switchMap((id) => this.get(id)),
      shareReplay(1)
    );
  }

  set(id: string): void {
    this._productionId$.next(id);
  }

  getAll(): Observable<IProductionViewDto[]> {
    return this.http
      .get<IProductionViewDto[]>(`${this.ROOT_URL}`)
      .pipe(shareReplay(1));
  }

  getCached(id: string): Observable<IProductionViewDto> {
    return this.cache.asObservable().pipe(
      map((c) => c.get(id)),
      filter(
        (production): production is IProductionViewDto => production != null
      )
    );
  }

  get(id: string): Observable<IProductionViewDto> {
    if (this.cache.value.has(id)) {
      return this.getCached(id);
    }

    const newMap = new Map(this.cache.value);
    newMap.set(id, null);
    this.cache.next(newMap);

    return this.http.get<IProductionViewDto>(`${this.ROOT_URL}/id/${id}`).pipe(
      switchMap((p) => {
        const newMap = new Map(this.cache.value);
        newMap.set(id, p);
        this.cache.next(newMap);

        return this.getCached(id);
      })
    );
  }

  getConfirmedReservationsPerShow$(
    productionId: string
  ): Observable<IConfirmedReservationsPerShow[]> {
    return this.http.get<IConfirmedReservationsPerShow[]>(
      `${this.ROOT_URL}/confirmed-reservations-per-show/${productionId}`
    );
  }

  put(id: string, production: IProduction): Observable<IProductionViewDto> {
    return this.http
      .put<IProductionViewDto>(`${this.ROOT_URL}/id/${id}`, production)
      .pipe(shareReplay(1));
  }

  create(production: IProduction): Observable<IProductionViewDto> {
    return this.http
      .post<IProductionViewDto>(`${this.ROOT_URL}`, production)
      .pipe(shareReplay(1));
  }

  bootstrap(id?: string): Observable<IProductionBootstrapDto> {
    if (id) {
      return this.http
        .get<IProductionBootstrapDto>(`${this.ROOT_URL}/bootstrap/${id}`)
        .pipe(shareReplay(1));
    }

    return this.bootstrap$;
  }

  public getProducts(production: IProductionViewDto): IProduct[] {
    return production.products.filter((p) => p.visible !== false);
  }

  public loadBootstrap(): void {
    this.http
      .get<IProductionBootstrapDto>(`${this.ROOT_URL}/bootstrap`)
      .pipe(take(1))
      .subscribe((bootstrap) => this._bootstrap$.next(bootstrap));
  }

  public getCoupons(productionId: string): Observable<ICoupon[]> {
    return this.http.get<ICoupon[]>(
      `${this.ROOT_URL}/id/${productionId}/coupons`
    );
  }

  public createCoupon(
    productionId: string,
    coupon: ICoupon
  ): Observable<ICoupon> {
    return this.http.post<ICoupon>(
      `${this.ROOT_URL}/id/${productionId}/coupons`,
      coupon
    );
  }

  public deleteCoupon(
    productionId: string,
    couponId: string
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.ROOT_URL}/id/${productionId}/coupons/${couponId}`
    );
  }

  public getCoupon(
    productionId: string,
    couponCode?: string
  ): Observable<ICoupon | null> {
    if (!couponCode) {
      return of(null);
    }

    return this.http
      .get<ICoupon | null | undefined>(
        `${this.ROOT_URL}/id/${productionId}/coupon/${couponCode}`
      )
      .pipe(map((coupon) => coupon ?? null));
  }
}
